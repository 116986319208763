<template>
  <div>
    <v-container fluid>
      <v-row class="justify-center">
        <v-row align="center" justify="center" style="height: 95vh" dense>
          <!-- Password Recovery Part1 -->
          <v-col
            v-if="isPart1"
            cols="12"
            class="
              lighten-2
              fill-height
              d-flex
              flex-column
              justify-center
              align-center
            "
          >
            <v-card flat tile class="white rounded-lg px-4" width="400px">
              <v-card-title class="justify-center pt-7 px-7"
                ><h2>Password Recovery</h2></v-card-title
              >
              <div
                class="
                  d-flex
                  justify-center
                  text-caption
                  mt-n3
                  grey--text
                  lighten-1
                "
              >
                Verify E-mail Address (Part 1)
              </div>
              <v-form @submit.prevent ref="part1Formref">
                <v-row class="mx-5 pt-6">
                  <v-col cols="12" class="pa-0">
                    <v-text-field
                      :rules="[formRules.required]"
                      v-model="email"
                      :loading="isLoading"
                      color="green"
                      class="text-caption"
                      label="Email Address"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex justify-center py-2">
                      <v-btn
                        class="log white--text rounded-pill py-2 px-8"
                        @click="toPart2"
                        :loading="isLoading"
                        >Verify</v-btn
                      >
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex justify-center pt-8 pb-3 text-caption">
                      Already have an account?<router-link class="pl-1" to="/"
                        >Sign In</router-link
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
          <!-- Password Recovery Part2 -->
          <v-col
            v-if="isPart2"
            cols="12"
            class="
              lighten-2
              fill-height
              d-flex
              flex-column
              justify-center
              align-center
            "
          >
            <v-card flat tile class="white rounded-lg px-4" width="400px">
              <v-card-title class="justify-center pt-7 px-7">
                <h2>Verify Your Account</h2>
                </v-card-title>
              <div
                class="
                  d-flex
                  justify-center
                  text-caption
                  mt-n3
                  grey--text
                  lighten-1
                "
              >
                OTP Confirmation (Part 2)
              </div>
              <v-divider class="mx-4"></v-divider>
              <div
                class="
                  d-flex
                  justify-center
                  text-caption
                  mt-2
                  px-5
                  grey--text
                  lighten-1
                "
              >
                A Confirmation code has been sent to your email address. Please
                Enter your OTP Code in the field below.
              </div>
              <v-row class="mx-5 pt-6">
                <v-col cols="12" class="pa-0 mt-6 d-flex justify-center">
                  <v-otp-input
                    ref="otpInput"
                    input-classes="otp-input"
                    separator="-"
                    :num-inputs="4"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    @on-complete="handleOnComplete"
                  />
                </v-col>
                <v-col cols="12">
                  <div class="d-flex justify-center py-2 pt-9">
                    <v-btn
                      class="log white--text rounded-pill py-2 px-8"
                      :loading="isLoading"
                      @click="confirmOtp()"
                      >Confirm</v-btn
                    >
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex justify-center pt-8 pb-3 text-caption">
                    Already have an account?<router-link class="pl-1" to="/"
                      >Sign In</router-link
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- Password Recovery Part3 -->
          <v-col
            v-if="isPart3"
            cols="12"
            class="
              lighten-2
              fill-height
              d-flex
              flex-column
              justify-center
              align-center
            "
          >
            <v-card flat tile class="white rounded-lg px-4" width="400px">
              <v-card-title class="justify-center pt-7 px-7"
                ><h2>Password Recovery</h2></v-card-title
              >
              <div
                class="
                  d-flex
                  justify-center
                  text-caption
                  mt-n3
                  grey--text
                  lighten-1
                "
              >
                Create New Password (Part 3)
              </div>
              <v-form @submit.prevent ref="part2Formref">
                <v-row class="mx-5 pt-6">
                  <v-col cols="12" class="pa-0">
                    <v-text-field
                      color="green"
                      class="text-caption"
                      label="New Password"
                      prepend-inner-icon="mdi-lock-plus"
                      v-model="newpass"
                      :rules="[formRules.required, formRules.password]" 
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-text-field
                      color="green"
                      class="text-caption"
                      label="Confirm New Password"
                      prepend-inner-icon="mdi-lock-check"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      v-model="confirmnewpass"
                      :rules="[formRules.required, formRules.confirmPassword(confirmnewpass, newpass)]" 
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex justify-center py-2">
                      <v-btn
                        class="log white--text rounded-pill py-2 px-8"
                        @click="toPart3"
                        >Set New Password</v-btn
                      >
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex justify-center pt-8 pb-3 text-caption">
                      Already have an account?<router-link class="pl-1" to="/"
                        >Sign In</router-link
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <fade-away-message-component
          displayType="variation2"
          v-model="fadeAwayMessage.show"
          :message="fadeAwayMessage.message"
          :header="fadeAwayMessage.header"
          :top="fadeAwayMessage.top"
          :type="fadeAwayMessage.type"
        ></fade-away-message-component>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PasswordReset",

  data: () => ({
    email: null,
    otp: null,
    newpass: null,
    confirmnewpass: null,
    done: false,
    emailError: "",
    emailChecking: false,
    isLoading: false,
    isPart1: true,
    isPart2: false,
    isPart3: false,
    isPart4: false,
    valid: false,
    show1: false,
    show2: false,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
  }),
  mounted() {
  },
  methods: {
    confirmOtp() {
      if (this.otp) {
        this.isLoading = true;
        let data = new FormData();
        data.append("email", this.$route.query.email);
        data.append("otp", this.otp);
        this.axiosCall("/reset/password/step2", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
              this.isPart2 = !this.isPart2;
              this.isPart3 = !this.isPart3;
          } else {
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "error";
              this.fadeAwayMessage.message = res.data.message;
              this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
    toPart2() {
      if (this.$refs.part1Formref.validate()) {
        this.isLoading = true;
        let data = new FormData();
        data.append("email", this.email);
        this.axiosCall("/reset/password/step1", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
                 this.isPart1 = !this.isPart1;
                 this.isPart2 = !this.isPart2;
                 this.$router.push({query: { email: this.email }});
          } else {
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "error";
              this.fadeAwayMessage.message = res.data.message;
              this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
    toPart3() {
      if (this.$refs.part2Formref.validate()) {
        this.isLoading = true;
        let data = new FormData();
        data.append("email", this.email);
        data.append("password", this.newpass);
        this.axiosCall("/reset/password/step3", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
           this.$router.replace({path: '/'});
          } else {
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "error";
              this.fadeAwayMessage.message = res.data.message;
              this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },

    handleOnComplete(value) {
      this.otp = value;
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
  },
};
</script>

<style scoped>
.back {
  background: url("../assets/thon.png") no-repeat center center fixed !important;
  background-color: rgb(49, 112, 49);
  background-size: cover;
  overflow: hidden !important;
}
.content {
  /* background: url('../assets/BG_3rd_Layer.png')
    no-repeat center center fixed !important; */
  background: url("../assets/BG_1st_Layer.png") no-repeat center fixed,
    linear-gradient(0deg, rgba(0, 115, 52, 1) 0%, rgba(46, 92, 7, 1) 100%);
  /* background: rgb(0,115,52);
    background: linear-gradient(90deg, rgba(0,115,52,1) 0%, rgba(108,125,11,1) 100%); */
  background-size: cover;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--ntp-one-google-bar-height));
  min-width: fit-content;
  position: relative;
  z-index: 1;
}
.log {
  background: #048b2c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #769c32,
    #048b2c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #769c32,
    #048b2c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.leg {
  background: #808080; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #34411f,
    #808080
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #34411f,
    #808080
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.error {
  border: 1px solid red !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.v-text-field label {
  font-size: 10pt;
}
.hh {
  height: 90vh;
}
</style>
